<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查询</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="Account Name:" style="width:300px;">
						<el-input v-model="queryForm.accountName" placeholder="请输入" size="mini"></el-input>
					</form-item>
					<form-item label="广告类型:" style="width:200px;">
						<el-select v-model="queryForm.type" placeholder="请选择" size="mini" clearable>
							<el-option v-for="(adType, index) in adTypes" :label="adType" :value="index"></el-option>
						</el-select>
					</form-item>
					<form-item-publishtypes v-model="queryForm.publishType" clearable></form-item-publishtypes>
					<form-item label="发布批次:" style="width:200px;">
						<el-input v-model="queryForm.batchNo" placeholder="请输入" size="mini"></el-input>
					</form-item>
					<form-item label="发布状态:" style="width:200px;">
						<el-select v-model="queryForm.publishStatus" placeholder="请选择" size="mini" clearable>
							<el-option v-for="publishState in publishStates" :label="publishState.value"
								:value="publishState.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="Campaign Name:" style="width:300px;">
						<el-input v-model="queryForm.campaignName" placeholder="请输入" size="mini"></el-input>
					</form-item>
					<form-item label="生成发布任务日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="timestamp" style="width:250px;" size="mini"
							:clearable="false">
						</el-date-picker>
					</form-item>
					<form-item label="发布来源:" style="width:400px;">
						<el-select v-model="queryForm.automation" size="mini">
							<el-option label="手动" :value="false"></el-option>
							<el-option label="自动" :value="true"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<alert v-if="multipleSelection.length > 0" class="my-2">
				已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}</span>个。
				<el-button type="text" @click="batchPublish('retry')" size="mini">批量重新发布</el-button>
				<el-button type="text" @click="batchPublish('preview')" size="mini">批量重新预览发布</el-button>
			</alert>
			<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange"
				@select="pinSelect">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="站点" prop="website.code" min-width="120">
				</el-table-column>
				<el-table-column label="发布批次" prop="batchNo" min-width="200">
				</el-table-column>
				<el-table-column label="Account" prop="accountName" min-width="200">
				</el-table-column>
				<el-table-column label="广告类型" min-width="150">
					<template slot-scope="scope">
						{{getAdType(scope.row)}}
					</template>
				</el-table-column>
				<el-table-column label="发布类型" prop="publishType" min-width="150">
				</el-table-column>
				<el-table-column label="Campaign Name" prop="campaignName" min-width="200">
				</el-table-column>
				<el-table-column label="Campaign ID" prop="campaignId" min-width="200">
				</el-table-column>
				<el-table-column label="Adset Name" prop="adSetName" min-width="200">
				</el-table-column>
				<el-table-column label="Adset ID" prop="adSetId" min-width="200">
				</el-table-column>
				<el-table-column label="Ad Name" prop="adName" min-width="200">
				</el-table-column>
				<el-table-column label="Ad ID" prop="adId" min-width="200">
				</el-table-column>
				<el-table-column label="生成发布任务时间" min-width="200">
					<template slot-scope="scope">
						{{scope.row.createdAt|formatDate('YYYY-MM-DD HH:mm')}}
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="100">
					<template slot-scope="scope">
						<div v-for="publishState in publishStates" v-if="publishState.key === scope.row.publishStatus" :class="`text-${publishState.style}`">{{publishState.value}}</div>
					</template>
				</el-table-column>
				<el-table-column label="Retry num" min-width="100" align="center">
					<template slot-scope="scope">
						<div>{{scope.row.retryNum || '-'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="Error msg" min-width="200">
					<template slot-scope="scope">
						<div v-html="getErrorMsg(scope.row)"></div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.publishStatus !== 'QUEUED'" class="d-flex flex-column align-items-center">
							<div>
								<el-button type="text" @click="batchPublish('retry', scope.row)" size="mini">重新发布</el-button>
							</div>
							<div>
								<el-button type="text" @click="batchPublish('preview', scope.row)" size="mini">重新预览发布</el-button>
							</div>
						</div>
						<div v-else>-</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import Alert from '@/components/alert/index.vue'
	import FormItemPublishtypes from '@/components/form-items/form-item-publishtypes.vue'
	import FormItemSites from '@/components/form-items/form-item-sites.vue'
	import {
		mapActions,
		mapState,
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		inject: ['adminLayout'],
		mixins: [Page, Common, MultipleTableSelection],
		components: {
			Alert,
			FormItemPublishtypes,
			FormItemSites
		},
		data() {
			return {
				queryForm: {
					website: '',
					accountName: '',
					publishType: '',
					type: '',
					batchNo: '',
					publishStatus: '',
					campaignName: '',
					date: [
						Moment().startOf('day').add(-6, 'days').format('x'),
						Moment().startOf('day').format('x')
					],
					automation: false
				},
				q: {
					website: '',
					createdAtMin: (Moment().startOf('day').add(-6, 'days').format('x')),
					createdAtMax: Moment().endOf('day').format('x')
				},
				tableData: [],
				fetchOnCreated: false
			}
		},
		computed: {
			...mapGetters([
				'publishTypes',
				'adTypes',
				'publishStates'
			])
		},
		methods: {
			...mapActions([
				'fetchRecords',
				'rePublish',
				'rePreviewAndPublish'
			]),
			batchPublish(type, row = null) {
				var tipTitle = ''
				switch(type) {
					case 'retry':
						tipTitle = `是否${row === null ? '批量': ''}重新发布?`
						break
					case 'preview':
						tipTitle = `是否${row === null ? '批量': ''}重新预览发布?`
						break
				}
				this.$confirm(tipTitle, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const body = row || this.multipleSelection
					const batch = Array.isArray(body)
					var api
					switch (type) {
						case 'retry':
							api = this.rePublish
							break
						case 'preview':
							api = this.rePreviewAndPublish
							break
					}
					this.adminLayout.showLoading()
					api({body, batch})
					.then(res => {
						this.adminLayout.hideLoading()
						if (batch) {
							this.$refs.multipleTable.clearSelection()
						}
						this.getList()
					})
					.catch(err => {
						debugger
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdAtMin: this.queryForm.date[0],
					createdAtMax: Number(this.queryForm.date[1]) + 3600 * 1000 * 24
				}
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchRecords({
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			getErrorMsg(row) {
				try {
					const {error: {error_user_msg, error_user_title, type, message}} = JSON.parse(row.errorMsg)
					if (error_user_msg && error_user_title) {
						return (`<div class="record-error record-error-title">${error_user_title}</div><div class="record-error">${error_user_msg}</div>`)
					}
					return (`<div class="record-error record-error-title">${type}</div><div class="record-error">${message}</div>`)
				} catch (e) {
					console.error(e)
				}
				return '<div class="record-error">-</div>'
			},
			getAdType(row) {
				if (typeof row.type === 'number') {
					return this.adTypes[row.type]
				}
			}
		}
	}
</script>

<style lang="scss">
	.record-error-title {
		font-weight:600;
	}
	.record-error {
		font-size:0.8rem;
	}
</style>
